import React, { useState } from "react";
import { CiLight } from "react-icons/ci";
import { FaCog, FaSignOutAlt, FaUser } from "react-icons/fa";
import { MdDarkMode } from "react-icons/md";
import { PiPlugsConnectedBold } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toggleTheme } from "../../Redux/Reducers/theme";
import { selectTheme } from "../../Redux/Selectors/theme";
import Breadcrumb from "../../components/Breadcrumb";
import { useOpenState } from "../../hooks/useOpenState";
import useOutsideClick from "../../hooks/useOutsideClick";
import useUserInfo from "../../hooks/useUserInfo";
import MoodleService from "../../services/Moodle";
import Dropdown, { DropdownItemInterface } from "./Dropdown";
import MoodleConnectModal from "./Modals/ConnectMoodle";
import Cookies from "js-cookie";
import { Menu, MenuOpen } from "@mui/icons-material";
import { Drawer } from "@mui/material";
import Sidebar from "layout/Sidebar";
const Navbar: React.FC = () => {
  const { university } = useUserInfo();
  const dispatch = useDispatch();
  const theme = useSelector(selectTheme);
  const location = useLocation();
  const navigate = useNavigate();
  const moodleService = new MoodleService();
  const [isOpen, handleIsOpen] = useOpenState({ moodleConnect: false });
  const [isLeftNavOpen, setIsLeftNavOpen] = useState(false);

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const { email, firstName, lastName, username } = useUserInfo();

  const pathnames = location.pathname.split("/").filter((x) => x);

  const toggleDarkMode = () => {
    dispatch(toggleTheme());
  };
  const dropdownOutsideClickRef = useOutsideClick(() => toggleDropdown());
  const handleSignOut = () => {
    // Delete user and token from localStorage
    localStorage.removeItem("user");
    Cookies.remove("token"); // 'token' is the name of your cookie

    window.location.href = "/login";
  };
  const dropdownItems: DropdownItemInterface[] = [
    {
      label: "My Profile",
      onClick: () => {
        navigate("/profile");
        setIsDropdownVisible(false);
      },
      icon: FaUser,
    },
    // {
    //   label: "Settings",
    //   onClick: () => console.log("Settings clicked"),
    //   icon: FaCog,
    // },
    // {
    //   label: "Connect Moodle",
    //   onClick: () => handleIsOpen("moodleConnect", true),
    //   icon: PiPlugsConnectedBold,
    // },
    {
      label: "Sign Out",
      onClick: handleSignOut,
      icon: FaSignOutAlt,
      split: true,
    },
  ];
  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  return (
    <nav className="w-max-max mx-auto flex h-16 items-center justify-between p-2 duration-500   ">
      <div className="lg:hidden">
        <a
          id="logo"
          href="/"
          className=" flex items-center justify-center gap-3 self-stretch"
        >
          <img
            className="relative h-14 w-14 rounded-full"
            src={
              university && university.logo
                ? university.logo
                : "/assets/logo/logo-no-background.png"
            }
            alt="Logo"
          />
        </a>
      </div>
      {isOpen.moodleConnect && (
        <MoodleConnectModal
          handleClose={() => handleIsOpen("moodleConnect", false)}
        />
      )}
      <div className="ml-4">{<Breadcrumb />}</div>
      {/* <div className="inline-flex items-center justify-start gap-10 self-stretch text-main-gray-700 dark:text-main-gray-500">
        {MenuList.map(({ name, href }: NavElementInterface, idx: number) => {
          const isActive = currentPath === href;
          return (
            <NavElement name={name} href={href} isActive={isActive} key={idx} />
          );
        })}
      </div> */}
      <div
        id="profile"
        className="ml-auto inline-flex items-center gap-2 md:gap-5 self-stretch "
      >
        {process.env.REACT_APP_ENV === "DEV" && (
          <div className="text-black dark:text-white">DEV</div>
        )}
        <div className="relative inline-block items-center text-left">
          <button
            className="focus:shadow-outline-blue inline-flex w-full justify-center gap-4 rounded-md hover:text-main-green-700 dark:text-white dark:hover:text-main-green-700"
            onClick={toggleDropdown}
          >
            <div className="hidden flex-col items-start justify-center md:inline-flex  ">
              <div className="w-max text-base font-bold ">{username}</div>
              <div className="w-max text-sm font-normal  dark:text-main-gray-300">
                {email}
              </div>
            </div>
            <img
              className="h-10 w-10 rounded-full"
              src={process.env.PUBLIC_URL + "/default-profile-pic.png"}
            />
          </button>
          {isDropdownVisible && (
            <Dropdown
              items={dropdownItems}
              dropdownOutsideClickRef={dropdownOutsideClickRef}
            />
          )}
        </div>
        <button
          className=" group flex items-center gap-3.5 text-main-green-700 dark:text-main-green-500"
          onClick={toggleDarkMode}
        >
          {theme === "dark"
            ? React.createElement(MdDarkMode, { size: 32 })
            : React.createElement(CiLight, { size: 32 })}
        </button>
        <button
          className="lg:hidden group flex items-center text-main-green-700 dark:text-main-green-500"
          onClick={() => setIsLeftNavOpen(true)}
        >
          <Menu />
        </button>
      </div>
      <Drawer
        anchor="left"
        className="lg:hidden"
        open={isLeftNavOpen}
        onClose={() => {
          setIsLeftNavOpen(false);
        }}
      >
        <div className="bg-gray flex h-screen bg-white duration-500 dark:bg-main-blue-main ">
          <Sidebar open />
        </div>
      </Drawer>
    </nav>
  );
};

export default Navbar;
// const MenuList = [
//   {name: "Home",href: "/",},
//   {name: "My Classes",href: "/myClasses",},
//   {name: "Dashboard",href: "/dashboard",},
// ];

// interface NavElementInterface {
//   isActive?: boolean;
//   name: string;
//   href: string;
// }

// const NavElement = ({
//   isActive = false,
//   name,
//   href,
// }: NavElementInterface) => {
//   return (
//     <a
//       className={` text-base font-semibold leading-normal  hover:text-main-green-700  dark:hover:text-main-green-500
//       ${isActive && `text-main-green-700 dark:text-main-green-500`}`}
//       href={href}
//     >
//       {name}
//     </a>
//   );
// };
