import React, { useState } from "react";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiTwotoneCalendar,
} from "react-icons/ai";
import { BiHomeAlt, BiLogOut } from "react-icons/bi";
import { FaUniversity } from "react-icons/fa";
import { RiSettings4Line } from "react-icons/ri";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./index.css";
import { FaUsers } from "react-icons/fa6";

import { BiSearch } from "react-icons/bi";
import { useAccessLevelStatus } from "../../hooks/useAccessLevelStatus";
import useUserInfo from "../../hooks/useUserInfo";
import { isVisible } from "@testing-library/user-event/dist/utils";
import { BsFiletypeJson } from "react-icons/bs";

interface CustomSideElementInterface {
  title: string;
  href: string;
  isActive?: boolean;
  icon: any;
}
interface SidebarProps {
  open?: boolean;
}
const Sidebar = ({ open = false }: SidebarProps) => {
  const { admin, teacher, student } = useAccessLevelStatus();
  const [isOpen, setIsOpen] = useState(open);
  const { university } = useUserInfo();

  const menus = [
    { title: "Home", href: "/", icon: BiHomeAlt, isVisible: true },
    // { title: "My Classes", href: "/myClasses", icon: IoMdSchool },
    {
      title: "My Campus",
      href: "/campuses",
      icon: FaUniversity,
      isVisible: admin,
    },
    // { title: "Integration", href: "/integration", icon: PiPlugsConnectedBold },
    // {
    //   title: "Calendar",
    //   href: "/calendar",
    //   icon: AiTwotoneCalendar,
    //   isVisible: true,
    // },
    {
      title: "Users",
      href: "/users",
      icon: FaUsers,
      isVisible: admin,
    },
    {
      title: "Json Editor",
      href: "/jsoneditor",
      icon: BsFiletypeJson,
      isVisible: admin,
    },
  ];
  const currentPath = useLocation().pathname;
  const navigate = useNavigate();

  const CustomSideElement = ({
    title,
    href,
    isActive = false,
    icon,
  }: CustomSideElementInterface) => {
    return (
      <Link
        className="flex flex-col items-start justify-start gap-2.5 rounded-lg p-4
         text-black hover:text-main-green-700
         hover:shadow-sm hover:shadow-main-green-700 dark:text-white dark:hover:text-main-green-500"
        title={title}
        to={href}
      >
        <div
          className={`inline-flex h-6 items-center justify-start gap-4  ${
            isOpen ? "w-48" : " "
          }`}
        >
          <div
            className={`${
              isActive && `text-main-green-700 dark:text-main-green-500`
            }`}
          >
            {React.createElement(icon, { size: 30 })}
          </div>
          <div
            className={`text-base font-semibold leading-snug  ${
              isOpen ? "block" : "hidden"
            }
        ${isActive && `text-main-green-700 dark:text-main-green-500`}`}
          >
            {title}
          </div>
        </div>
      </Link>
    );
  };
  const logout = () => {
    // Clear user information from localStorage
    localStorage.removeItem("user");
    // Redirect to the "/login" page
    navigate("/login");
  };
  return (
    <div
      className={`inline-flex h-full w-16 flex-col items-start justify-between gap-8  pb-8 pt-2
     text-main-green-700 duration-500  ${isOpen ? "w-60" : " "}`}
    >
      <div
        id="top-section"
        className="flex flex-col items-center justify-start gap-9 self-stretch" // Add `justify-start` to place it at the start
      >
        <a
          id="logo"
          href="/"
          className=" flex items-center justify-center gap-3 self-stretch"
        >
          <img
            className="relative h-14 w-14 rounded-full"
            src={
              university && university.logo
                ? university.logo
                : "/assets/logo/logo-no-background.png"
            }
            alt="Logo"
          />
          {isOpen && (
            <h3 className="animate-character">
              {university && university.name ? university.name : "Aimly"}
            </h3>
          )}
        </a>

        {/* <div
          id="search"
          className=" flex flex-row  items-start justify-center   rounded-2xl bg-main-gray-300 p-3 dark:bg-gray-900"
        >
          <div className="relative h-6 w-6">
            {React.createElement(BiSearch, { size: 25 })}
          </div>
          <div
            className={`hidden h-6 w-40 items-center justify-start ${
              isOpen ? "md:inline-flex" : " "
            } `}
          >
            <input
              className="mr-3 w-full appearance-none border-none bg-transparent px-2 py-1 leading-tight 
               text-main-gray-700 focus:text-black focus:outline-none dark:text-main-gray-700 dark:focus:text-white"
              type="text"
              placeholder="Search..."
              name="brand_name"
            />
          </div>
        </div> */}
        <div
          id="menus"
          className="flex flex-col items-start justify-start gap-3"
        >
          {menus
            .filter((obj) => obj.isVisible)
            .map(
              (
                { title, href, icon }: CustomSideElementInterface,
                idx: number,
              ) => {
                const isActive = currentPath === href;
                return (
                  <CustomSideElement
                    title={title}
                    href={href}
                    icon={icon}
                    key={idx}
                    isActive={isActive}
                  />
                );
              },
            )}
        </div>
      </div>
      <div
        id="bottom-section"
        className="flex flex-col items-center justify-end gap-2 self-stretch "
      >
        <div className="hidden lg:block relative mb-2 w-full">
          <div
            id="openclose"
            className="absolute end-0 z-10 ml-auto mr-[-15px] cursor-pointer justify-end rounded-full bg-main-green-500 p-1 text-black dark:bg-main-green-700 dark:text-white"
            onClick={() => setIsOpen((prev) => !prev)}
          >
            {isOpen
              ? React.createElement(AiOutlineArrowLeft, { size: 30 })
              : React.createElement(AiOutlineArrowRight, { size: 30 })}
          </div>
        </div>

        {/* <button className=" flex flex-col items-center justify-center gap-2.5 rounded-lg p-4 text-black hover:text-main-green-700 hover:shadow-sm  hover:shadow-main-green-700 dark:text-white dark:hover:text-main-green-500">
          <div
            className={`inline-flex h-6 items-center  justify-start gap-4 ${
              isOpen ? " md:w-48" : " "
            }`}
          >
            <div className="text-main-green-700">
              {React.createElement(RiSettings4Line, { size: 30 })}
            </div>
            <div
              className={`hidden text-base font-normal leading-snug  ${
                isOpen ? " md:block" : " "
              }`}
            >
              Settings
            </div>
          </div>
        </button> */}
        <button
          onClick={logout}
          className=" flex flex-col items-center justify-center gap-2.5 rounded-lg p-4 text-black hover:text-main-green-700 hover:shadow-sm  hover:shadow-main-green-700 dark:text-white dark:hover:text-main-green-500"
        >
          <div
            className={`inline-flex h-6 items-center  justify-start gap-4 ${
              isOpen ? " w-48" : " "
            }`}
          >
            <div className="text-main-green-700">
              {React.createElement(BiLogOut, { size: 30 })}
            </div>
            <div
              className={`text-base font-normal leading-snug  ${
                isOpen ? "block" : "hidden"
              }`}
            >
              Logout
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
